<template>
  <div class="width100 box-sizing padding3 flex need-zone">
    <div class="need-zone-left flex1 box-sizing">
      <div class="flex flex-center padding1 box-sizing">
        <el-checkbox v-model="thisCity">
          <span>同城</span>
        </el-checkbox>
        <el-checkbox v-model="thisProvince">
          <span>同省</span>
        </el-checkbox>
        <el-dropdown @command="handleCommand" class="margin-left5">
          <div class="el-dropdown-link">
            {{ renderTime }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="1">1小时内</el-dropdown-item>
            <el-dropdown-item :command="2">最近3小时</el-dropdown-item>
            <el-dropdown-item :command="3">最近5小时</el-dropdown-item>
            <el-dropdown-item :command="4">最近12小时</el-dropdown-item>
            <el-dropdown-item :command="5">最近1天</el-dropdown-item>
            <el-dropdown-item :command="6">最近3天</el-dropdown-item>
            <el-dropdown-item :command="7">最近5天</el-dropdown-item>
            <el-dropdown-item :command="8">最近10天</el-dropdown-item>
            <el-dropdown-item :command="0">不限时间</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="flex1"></div>
      </div>
      <div class="margin-top2 overflow-x-auto overflow-y-auto padding1 box-sizing" style="height: 500px;"
           v-infinite-scroll="load" infinite-scroll-disabled="false" infinite-scroll-immediate="true" infinite-scroll-distance="1">
        <div v-for="(item, index) in dataList" :key="index" class="need-zone-left-item box-sizing padding2 box-shadow5 border-radius1 margin-bottom3">
          <div class="flex flex-center">
            <img class="head-portrait border-radius100" :src="item.avatar || defaultImg" alt="头像"/>
            <div class="flex1 fontSize14 textColor03 margin-left2">
              <span>{{ titleValue(item) }}</span>
              <span v-if="item.companyType" class="fontSize12 textColor23">
                （{{ renderCompanyType(item.companyType) }}）
              </span>
            </div>
            <div class="flex flex-center fontSize14 textColor23 ">
              <span class="cursor-pointer actives05 margin-left3" @click="toLoginFn" v-if="hasCookie && !profile.hasCompany">
                登录后可联系对方
              </span>
              <span class="cursor-pointer actives05 margin-left3" @click="toBindCompanyFn" v-if="!hasCookie">
                认证后可联系对方
              </span>
              <img class="cursor-pointer actives05 margin-left3 message-btn" v-if="hasCookie && profile.hasCompany"
                   src="@/assets/home/message.png" alt="message" @click="sendMsg(item)"/>
            </div>
          </div>
          <div class="fontSize14 textColor03 margin-top2">
            <span>配件信息：</span>
            <span>{{ item.title }}</span>
          </div>
          <div class="flex fontSize12 margin-top2" v-if="item.brands.length > 0">
            <p v-for="(brand, index_b) in item.brands" :key="'brand' + index_b"
               class="padding-left2 padding-right2 line-height2 border-radius4 textColor23 background-color30 margin-right2">
              {{ brand }}
            </p>
          </div>
          <div class="fontSize12 textColor06 margin-top2 flex text-show-one" v-if="item.detail">
            {{ item.detail }}
          </div>
          <div class="flex margin-top2">
            <div v-for="(img, index_img) in item.pictures.slice(0, 8)" :key="'img' + index_img">
              <el-image
                alt=""
                fit="cover"
                :src="img"
                class="need-zone-left-item-imgs border-radius1 overflow-hidden"
                :preview-src-list="item.pictures"
              />
            </div>
          </div>
          <div class="flex fontSize13 margin-top2">
            <span class="textColor09">{{ item.createdAt }}</span>
            <span class="textColor23 margin-left4" v-if="item.replyCount">
              {{ item.replyCount || "0" }}回复
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="need-zone-right box-sizing margin-left3 margin-top6">
      <div class="width100 margin-top3 padding2 box-sizing min-height80 background-color-gradient08">
        <p class="fontSize14 textColor03">我有没找到的配件，我要发布求购信息到求购</p>
        <p class="fontSize13 textColor23">让全国配件商为我服务！</p>
      </div>
      <el-form v-show="$store.state.profile.id" ref="form" :model="form" size="small" label-position="top">
        <el-form-item label="配件信息">
          <el-input
            v-model="form.title"
            clearable
            placeholder="请输入配件名称或OE号，多个请用“，”分割">
          </el-input>
        </el-form-item>
        <el-form-item label="品质要求">
          <el-select
            v-model="form.qualities"
            clearable
            multiple
            placeholder="请选择类型">
            <el-option
              v-for="item in qualitys"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="详细说明">
          <el-input
            type="textarea"
            v-model="form.detail"
            placeholder="请输入详细说明">
          </el-input>
        </el-form-item>
        <el-form-item label="上传图片">
          <UploadFileImg
            :maxSize="1"
            listType="picture-card"
            :limit="4"
            :fileList="imgList"
            :isMultiple="false"
            :showFileList="true"
            :hideFlag="true"
            @getFileList="(fileList) => getFileList(fileList)"
          />
        </el-form-item>
      </el-form>
      <div class="flex flex-center">
        <div @click="onSubmit" class="background-color27 textColor10 fontSize16 border-radius4 line-height2 padding-left3 padding-right3 cursor-pointer actives05">
          发布求购
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {needListApi} from "@/api/home/need";
import {mapState} from "vuex";
import {STATUS} from "../../../store/index";
import UploadFileImg from "@/components/Upload/UploadFileImg";
import {publishNeedApi} from "@/api/home/need";

export default {
  name: "needZone",
  components: {
    UploadFileImg,
  },
  data() {
    return {
      isError: false,
      finished: false,
      loading: false,
      thisCity: false,
      lastTimeQuery: 0,
      thisProvince: false,
      pageNum: 0,
      pageSize: 20,
      dataList: [],
      defaultImg: require("@/assets/register/avatar1.png"),
      flag: true,
      filterObj: {},
      qualitys: [
        {
          label: "原厂件",
          value: "原厂件",
        },
        {
          label: "品牌件",
          value: "品牌件",
        },
        {
          label: "再制造件",
          value: "再制造件",
        },
        {
          label: "拆车件",
          value: "拆车件",
        },
        {
          label: "其他",
          value: "其他",
        },
      ],
      imgList: [],
      form: {
        title: "",
        detail: "",
        qualities: [],
        pictures: [],
      },
    };
  },
  computed: {
    ...mapState(["profile"]),
    renderTime() {
      if (!this.lastTimeQuery) {
        return "请选择时间范围";
      }
      const titleArr = [
        "1小时以内",
        "最近3小时",
        "最近5小时",
        "最近12小时",
        "最近1天",
        "最近3天",
        "最近5天",
        "最近10天",
      ];
      return titleArr[this.lastTimeQuery - 1];
    },
    hasCookie() {
      return this.$store.state.profile.id;
    },
  },
  watch: {
    thisCity() {
      this.loadList(true);
    },
    thisProvince() {
      this.loadList(true);
    },
    imgList: {
      handler: function(newVal) {
        this.form.pictures = 
          newVal && newVal.length > 0 ? newVal.map(item=> item.origin) : []
      },
      deep: true,
    },
  },
  created() {
    this.dataList = [];
    this.loadList();
  },
  methods: {
    getFileList(fileList) {
      this.imgList = fileList;
    },
    sendMsg(e) {
      if (!this.$store.state.profile.id) {
        this.$router.push("/login");
        return;
      }
      this.$store.dispatch("setChatId", e.userId);
      this.$store.dispatch("changeIMVisible", true);
    },
    toBindCompanyFn() {
      this.$store.dispatch("changeAuthStatus", STATUS.AUTH);
      this.$router.push("/login");
    },
    toLoginFn() {
      this.$router.push('/login')
    },
    renderName(name) {
      let str = "";
      if (this.hasCookie && this.profile.hasCompany) {
        str = name;
      } else {
        //只显示姓氏 eg:张**
        let char = "";
        for (let i = 0; i < name.length - 1; i++) {
          char += "*";
        }
        str = name.substring(0, 1) + char;
      }
      return str;
    },
    renderPhone(phone) {
      let str = "";
      if (this.hasCookie && this.profile.hasCompany) {
        str = phone;
      } else {
        //隐藏电话号码中间部分 eg:132****2222
        str = phone.replace(/^(\d{3})\d+(\d{4})$/, "$1****$2");
      }
      return str;
    },
    renderCompanyType(companyType) {
      let str = "";
      switch (companyType) {
        case "garage":
          str = "汽修厂";
          break;
        case "dealer":
          str = "经销商";
          break;
        case "manufacturer":
          str = "生产厂家";
          break;
      }
      return str;
    },
    toNeedFn() {
      if (this.hasCookie) {
        this.$router.push("/userCenter/need");
      } else {
        this.$router.push('/login')
      }
    },
    handleCommand(e) {
      this.lastTimeQuery = e;
      this.loadList(true);
    },
    onFilter(filterObj) {
      this.filterObj = {...filterObj};
      this.flag = true;
      this.loadList(true);
    },
    load() {
      this.loadList(false);
    },
    loadList(isSearch) {
      if (!this.flag) return;
      this.flag = false;
      let params = {};
      if (isSearch) {
        this.pageNum = 0;
        this.finished = false;
        this.dataList = [];
      }
      //关建字查找
      params = {
        thisCity: this.thisCity,
        thisProvince: this.thisProvince,
        lastTimeQuery: this.lastTimeQuery,
        page: ++this.pageNum,
        pageSize: this.pageSize,
      };
      if (Object.keys(this.filterObj).length) {
        params = Object.assign({}, params, this.filterObj);
      }
      this.$nextTick(() => {
        this.getOrder(params);
      });
    },
    getOrder(params) {
      needListApi(params)
        .then((res) => {
          if (res && res.rows && res.rows.length) {
            for (let item of res.rows) {
              if (!item.qualities) {
                item.qualities = []
              }
              if (!item.brands) {
                item.brands = []
              }
            }
            this.dataList = this.dataList.concat(res.rows);
            this.loading = false;
            if (this.pageSize > res.rows.length) {
              this.finished = true;
            }
            this.flag = true;
          } else {
            this.finished = true;
            this.loading = false;
            this.flag = true;
          }
          this.isError = false;
        })
        .catch((err) => {
          this.loading = false;
          this.isError = true;
          this.flag = true;
          this.pageNum = 0;
          if (err && err.message) {
            this.$message.error(err.message);
          }
        });
    },
    titleValue(item) {
      let value = "";
      if (item.companyName) {
        value = item.companyName + "-" + item.name + "-" + item.phone
      } else {
        value = item.name + "-" + item.phone
      }
      return value
    },
    onSubmit() {
      if (!this.$store.state.profile.id) {
        this.$router.push('/login')
        return
      }
      if (!this.form.title) {
        this.$message.warning('请输入配件信息')
        return
      }
      publishNeedApi(this.form).then(() => {
        this.$message.success('发布成功!')
        this.form = {
          title: "",
          detail: "",
          qualities: [],
          pictures: [],
        };
        this.imgList = []
        this.loadList(true);
      });
    },
  },
};
</script>

<style lang="less" scoped>
.need-zone-right {
  width: 364px;
}

.need-zone-left-item {

}

.need-zone-left-item .head-portrait {
  width: 40px;
  height: 40px;
}

.need-zone-left-item .message-btn {
  width: 20px;
  height: 20px;
}

.need-zone-left-item .need-zone-left-item-imgs {
  width: 40px;
  height: 40px;
  margin-right: 8px;
}

.el-form-item--small.el-form-item {
  margin-bottom: 10px !important;
}
</style>
