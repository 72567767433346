import { render, staticRenderFns } from "./needZone.vue?vue&type=template&id=b8ea581a&scoped=true&"
import script from "./needZone.vue?vue&type=script&lang=js&"
export * from "./needZone.vue?vue&type=script&lang=js&"
import style0 from "./needZone.vue?vue&type=style&index=0&id=b8ea581a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8ea581a",
  null
  
)

export default component.exports